import gql from 'graphql-tag'

export const CreateFolder = gql`
  mutation createFolder($input: CreateFolderInput!) {
    createFolder(input: $input) {
      clientMutationId
      success
    }
  }
`

export const CreateFile = gql`
  mutation createFile($input: CreateFileInput!) {
    createFile(input: $input) {
      clientMutationId
      success
    }
  }
`

export const DeleteFile = gql`
  mutation deleteFile($input: DeleteFileInput!) {
    deleteFile(input: $input) {
      clientMutationId
      success
    }
  }
`
