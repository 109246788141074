<template>
  <v-dialog
    width="800"
    v-model="dialog"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="mr-3 elevation-0"
        color="error"
        icon
        >
        <v-icon>
          mdi-delete
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        Eliminar archivo
      </v-card-title>

      <v-card-text>
        <div
          class="text-subtitle-1"
          >
          ¿Está seguro de que desea eliminar el archivo {{ file.filename }}? Esta acción <span class="error--text font-weight-medium">no se puede deshacer</span>.
        </div>

        <div
          class="text-right"
          >
          <v-btn
            class="rounded-lg mr-2"
            depressed
            @click="dialog = false"
            >
            Cancelar
          </v-btn>

          <v-btn
            color="error"
            class="rounded-lg"
            depressed
            @click="deleteFile"
            >
            Eliminar
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { DeleteFile } from '@/graphql/mutations/repository'

export default {
  data: () => ({
    dialog: false,
  }),

  props: {
    file: {
      type: Object,
      required: true
    }
  },

  methods: {
    deleteFile () {
      this.$apollo.mutate({
        mutation: DeleteFile,
        variables: {
          input: {
            id: this.file.id
          }
        }
      }).then ( res => {
        this.$emit('reload')
        this.dialog = false
      })
    },
  }
}
</script>
